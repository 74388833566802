import Decimal from "decimal.js-light";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle, faCheckCircle, faCircleArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { Fragment } from "react";
import { AmortizationSchedule } from "@/types";
import { Tooltip, formatAmountCents } from "@canopyinc/aura";

type StatusIconType =
  | "unpaid"
  | "paid"
  | "underpaid"
  | "unpaid_neutral"
  | "deferred_neutral"
  | "deferred_paid"
  | "deferred_unpaid";

interface StatusIconProps {
  status: StatusIconType;
}

/**
 * Renders a gray circle, a green check mark, or an orange check mark
 */
export const StatusIcon = (props: StatusIconProps) => {
  switch (props.status) {
    case "unpaid_neutral":
      return <div className="bg-gray-300 w-5 h-5 rounded-full" />;
    case "unpaid":
      return <FontAwesomeIcon icon={faXmarkCircle} className="text-red-500" />;
    case "underpaid":
      return <FontAwesomeIcon icon={faCheckCircle} className="text-amber-500" />;
    case "paid":
      return <FontAwesomeIcon icon={faCheckCircle} className="text-emerald-500" />;
    case "deferred_neutral":
      return <FontAwesomeIcon icon={faCircleArrowRight} className="text-primary-500" />;
    case "deferred_paid":
      return <FontAwesomeIcon icon={faCircleArrowRight} className="text-emerald-500" />;
    case "deferred_unpaid":
      return <FontAwesomeIcon icon={faCircleArrowRight} className="text-red-500" />;
    default:
      console.warn("Expected status icon to be returned");
      return null;
  }
};

function statusIconTypeFromScheduleRow(row: AmortizationSchedule): StatusIconType {
  // @ts-ignore
  const deferredCents = row.am_deferred_total_cents ?? 0;
  // due date has not elapsed
  if (row.paid_on_time == null) {
    if (deferredCents > 0) {
      return "deferred_neutral";
    }

    return (row.am_cycle_payment_cents ?? 0) > 0 ? "underpaid" : "unpaid_neutral";
  }

  if (!row.paid_on_time) {
    return deferredCents === 0 ? "unpaid" : "deferred_unpaid";
  }

  if (row.paid_on_time) {
    return deferredCents === 0 ? "paid" : "deferred_paid";
  }

  return "unpaid_neutral";
}

export function statusIconFromScheduleRow(row: AmortizationSchedule): ReturnType<typeof StatusIcon> {
  const iconType = statusIconTypeFromScheduleRow(row);

  if (["deferred_neutral", "deferred_unpaid", "deferred_paid"].includes(iconType)) {
    // disclose more information around the row's deferral activity
    const tooltipContent = (
      // @ts-ignore
      <span className="whitespace-nowrap">deferred amount: {formatAmountCents(row.am_deferred_total_cents ?? 0)}</span>
    );
    return (
      <Tooltip withPortal={false} content={tooltipContent}>
        <StatusIcon status={iconType}></StatusIcon>
      </Tooltip>
    );
  }
  return <StatusIcon status={iconType}></StatusIcon>;
}

export function amountDueText(row: AmortizationSchedule) {
  // @ts-ignore
  const deferredCents = row.am_deferred_total_cents ?? 0;
  const actualDuePlusDeferred = new Decimal(row.am_min_pay_cents).add(deferredCents).toNumber();
  const amountDueText =
    deferredCents > 0 ? (
      <Fragment>
        <span className="line-through">{formatAmountCents(actualDuePlusDeferred)}</span>
        &nbsp;
        <span>{formatAmountCents(row.am_min_pay_cents)}</span>
      </Fragment>
    ) : (
      formatAmountCents(row.am_min_pay_cents)
    );
  return amountDueText;
}
