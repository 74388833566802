import { flat } from "@/libs/flat";

export type ExternalField = { [key: string]: any };

export const parseExternalFields = (externalFields?: ExternalField | ExternalField[]) => {
  const transformKeyValueBlobs = (entry: ExternalField) => {
    const [key, value] = Object.values(entry);

    return { [`${key}`]: value };
  };

  const toJSONArray = (entry: ExternalField) => {
    return Object.entries(entry).map(([key, value]) => ({ [`${key}`]: value }));
  };

  const mapNormalization = (entry: ExternalField) => {
    const [key, value] = Object.keys(entry);

    const isIrregular = key === "key" && value === "value";
    return isIrregular ? transformKeyValueBlobs(entry) : toJSONArray(entry);
  };

  const transformed = Array.isArray(externalFields)
    ? externalFields.map(mapNormalization).flat(1)
    : toJSONArray(flat(externalFields));

  return transformed;
};
