import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NuqsAdapter } from "nuqs/adapters/next/pages";
import React from "react";
import dynamic from "next/dynamic";
import { SWRConfig } from "swr";
import { AccountDrawersProvider } from "@/context/AccountDrawersContext";
import { SettingsProvider } from "@/context/SettingsContext";
import { OnboardingProvider } from "@/context/OnboardingContext";
import { ScrollReset } from "@/components";
import { ApiProvider } from "@/context/ApiContext";
import { AnalyticsProviderWrapper as AnalyticsProvider } from "@/context/AnalyticsContext";
import { I18nProvider } from "@/context/i18n";
import { configureAuth } from "@/api/auth/configureAuth";
import { AuraProvider } from "@canopyinc/aura";

const DrawerManager = dynamic(() => import("@canopyinc/aura").then((module) => module.DrawerManager), { ssr: false });

// Next.js supports importing CSS from node_modules directly in components for effective code-splitting.
// Do that first. Otherwise, import global css here.
import "@/tailwind.css";
import { PluginsProvider } from "@/context/plugins";
import { getEnvConfig } from "@/config/environments";
import { NavBarProvider } from "@/context/NavBarContext";

configureAuth();

/**
 * App root
 */
const App = ({ Component, pageProps }) => {
  return (
    <Providers>
      <>
        <ScrollReset />
        <Component {...pageProps} />
      </>
    </Providers>
  );
};

export default dynamic(() => Promise.resolve(App), {
  ssr: false,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      // eslint-disable-next-line @vertical-made/no-arithmetic/no-arithmetic
      refetchInterval: 1000 * 60,
      /*
        Stale time is left at the default of 0 and refetch interval is set to 60 seconds.
        This means that as long as the window remains focused, data will be refetched:
          - Whenever user navigates to a different route
          - Whenever we explicitly call `invalidateQueries` or `refetch`
          - Every 60 seconds.
      */
    },
  },
});

/**
 * Keep all context providers here.
 */
export function Providers({ children }: { children: React.ReactElement }) {
  return (
    <ErrorBoundary>
      <SWRConfig
        value={{
          errorRetryCount: 3,
          onError: (err) => {
            // We ignore commonly expected error codes.
            // They are handled by interceptor in authService.tsx
            // Every other error is collected.
            if (err.status !== 401 && err.status !== 403 && err.status !== 404) {
              // Datadog will automatically collect errors
              console.error({ error: err });
            }
          },
          revalidateOnMount: true,
          revalidateOnFocus: false,
          revalidateOnReconnect: false,
        }}
      >
        <NuqsAdapter>
          <QueryClientProvider client={queryClient}>
            <ApiProvider>
              <PluginsProvider assetsUrl={getEnvConfig()?.assetsUrl ?? ""}>
                <I18nProvider assetsUrl={getEnvConfig()?.assetsUrl ?? ""}>
                  <NavBarProvider>
                    <AuraProvider>
                      <SettingsProvider>
                        <AccountDrawersProvider>
                          <DrawerManager>
                            <AnalyticsProvider />
                            <OnboardingProvider>{children}</OnboardingProvider>
                          </DrawerManager>
                        </AccountDrawersProvider>
                      </SettingsProvider>
                    </AuraProvider>
                  </NavBarProvider>
                </I18nProvider>
              </PluginsProvider>
            </ApiProvider>
          </QueryClientProvider>
        </NuqsAdapter>
      </SWRConfig>
    </ErrorBoundary>
  );
}

/**
 * https://reactjs.org/docs/error-boundaries.html
 *
 * Prevents our entire app from crashing due to some conspicuous null error or a different error.
 */
class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service
    console.error({ error, message: "Frontend encountered an uncaught exception." });
  }

  render() {
    return this.props.children;
  }
}
