export type QueryParamInput = {
  [key in string]?: string | number | boolean | null;
};

export const toQueryParamString = (obj?: QueryParamInput) =>
  new URLSearchParams(Object.fromEntries(Object.entries(obj ?? {}).map(([key, val]) => [key, `${val}`]))).toString();

export const appendQueryParams = (url: string, params?: QueryParamInput) => {
  const queryString = toQueryParamString(params);
  return queryString !== "" ? `${url}?${queryString}` : url;
};
