import { Card, DataGrid, formatAmountCents, formatMMDDYY, Text } from "@canopyinc/aura";
import Decimal from "decimal.js-light";
import { chunk } from "lodash";
import { useMemo, useState } from "react";

import { Pagination } from "@/components";
import { usePluginColumns, usePlugins } from "@/hooks";
import { mdash } from "@/libs/string";
import { amountDueText, statusIconFromScheduleRow } from "@/utils/amUtils";
import { TransactionDetailsProps } from ".";

export const AmortizationSchedule = ({ schedule, timeZone, transaction }: TransactionDetailsProps) => {
  const PAGE_SIZE = 12;
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const amScheduleChunks = useMemo(
    () =>
      chunk(
        schedule?.filter((scheduleItem) => scheduleItem.line_item_id === transaction?.transaction_id),
        PAGE_SIZE
      ),
    [schedule, PAGE_SIZE, transaction?.transaction_id]
  );
  const amScheduleSet = amScheduleChunks[currentPageIndex];

  const rootPlugins = usePlugins();
  const plugins = rootPlugins?.components?.transaction_details?.am_schedule;
  const columns = usePluginColumns(plugins?.fields);

  if (schedule == null || !plugins?.enabled) {
    return null;
  }

  const rows = amScheduleSet?.map((schedule) => {
    const amountPaid = schedule.am_cycle_payment_cents ? formatAmountCents(schedule.am_cycle_payment_cents) : null;
    return {
      status: <div className="text-xl flex leading-none justify-center">{statusIconFromScheduleRow(schedule)}</div>,
      min_pay_due_at: formatMMDDYY(schedule.min_pay_due_at, { exclusiveEnd: true, timeZone }),
      amount_due: amountDueText(schedule),
      amount_paid: amountPaid,
      fees: schedule.am_fees_cents != null ? formatAmountCents(schedule.am_fees_cents) : mdash,
      interest: formatAmountCents(schedule.am_interest_cents),
      principal: formatAmountCents(schedule.am_principal_cents),
      balance: formatAmountCents(schedule.am_end_total_balance_cents),
    };
  });

  return (
    <div className="flex flex-col gap-2">
      <Text as="h3">Amortization Schedule</Text>
      <Card>
        <DataGrid columns={columns} rows={rows ?? []} />
      </Card>
      <div className="flex justify-end mt-4">
        <Pagination currentPageIndex={currentPageIndex} pages={amScheduleChunks} onPageChange={setCurrentPageIndex} />
      </div>
    </div>
  );
};
